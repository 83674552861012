body,
.dark,
.light {
	@apply bg-white #{'!important'};
	font-optical-sizing: auto;
	font-style: normal;
	font-variation-settings: 'wdth' 100;
}

textarea[disabled], input[disabled], .mat-mdc-select-disabled .mat-mdc-select-value-text{
    @apply text-black #{!important};
}
/* -------------------------------------------------------------------------- */
/*  @ ANCHOR
/* -------------------------------------------------------------------------- */
a {
	transition: transform 100ms ease-in-out 0s;

	&:hover {
		text-decoration: underline;
		transform: translateY(-1px);
	}

	&:active {
		text-decoration: underline;
		transform: translateY(1px);
	}
}
.home-bg{
    background: url('../assets/images/bg/bg-home-3.png');
    @apply bg-cover bg-no-repeat bg-center;
}
.logo-dark-bg{
    background: url('../assets/images/logo/idems-dark.svg');
    @apply bg-contain bg-no-repeat w-64 h-16;
}
.logo-bg{
    background: url('../assets/images/logo/idems.svg');
    @apply bg-contain bg-no-repeat w-72 h-20;
}
.centvis-logo-bg{
    background: url('../assets/images/centvis/logo-white.svg');
    @apply bg-contain bg-no-repeat w-50 h-10;
}
/** PAGE HEADER CSS **/
.pageContainer {
    @apply flex flex-col flex-auto min-w-0;
}

.pageContent {
    @apply flex flex-col px-8 py-4;
}

.pageHeaderContainer {
    @apply min-h-18 flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-2 px-4 sm:px-8 border-b bg-card dark:bg-transparent;
}

.pageTitleContainer {
    @apply flex-1 min-w-0;
}

.breadcrumbTemplate {
    @apply flex flex-wrap items-center font-medium;
}

.pageTitleContent {
    @apply text-2xl md:text-3xl font-extrabold tracking-tight  truncate;
}
.primary-link {
    @apply underline underline-offset-4 decoration-primary hover:no-underline;
}
.dlLabel {
    @apply text-sm text-gray-400;
}
.dlData {
    @apply text-base text-gray-900;
}
.mat-mdc-menu-item {
    min-height: 36px !important;
}
.mat-mdc-option {
    min-height: 32px !important;
}

@media screen and (min-width: 1280px) {
    .xl-max-w-14 {
        max-width: 14%;
    }
    .xl-max-w-16 {
        max-width: 16.66%;
    }
    .xl-max-w-20 {
        max-width: 20%;
    }
}
.hide-subscript-wrapper {
    .mat-mdc-form-field-subscript-wrapper {
        @apply hidden #{!important};
    }
}

// custom css
.left-minus-2dot5 {
    left: -0.625rem;
}
dummy{
    @apply bg-blue-100 text-blue-600 border border-blue-400;
    @apply bg-yellow-100 text-yellow-600;
    @apply bg-blue-100 bg-blue-200 text-blue-600;
    @apply bg-purple-100 text-purple-600;
    @apply bg-green-100 text-green-600 font-semibold;
    @apply  bg-blue-100 text-blue-600 border border-blue-500;
    @apply col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6 col-span-7 col-span-8 col-span-9 col-span-10;
}
/*
.border.workflowStatusStart,
tr.workflowStatusStart {
    @apply border-x-2 border-x-blue-400  #{!important};
}
.border.workflowStatusComplete,
tr.workflowStatusComplete {
    @apply border-x-2 border-x-green-400  #{!important};
}
.border.workflowStatusInProcess,
tr.workflowStatusInProcess {
    @apply border-x-2 border-x-sky-400  #{!important};
}
.border.workflowStatusCancelled,
tr.workflowStatusCancelled {
    @apply border-x-2 border-x-red-600  #{!important};
}
*/
.min-w-6 {
    min-width: 1.5rem;
}
.min-h-6 {
    min-height: 1.5rem;
}
.max-h-56 {
    max-height: 14rem;
}
.min-h-56 {
    min-height: 14rem;
}
.max-h-72 {
    max-height: 18rem;
}
.w-184 {
    width: 46rem;
}
.w-230 {
    width: 57.5rem;
}
.pb-0dot75 {
    padding-bottom: 0.1875rem;
}

.align-middle {
    vertical-align: middle;
}

.border-0.border-b-3 {
    border-bottom: 3px solid #0569a0 !important;
}

.h-full-minus-205 {
    height: calc(100vh - 205px);
}
.h-full-minus-328 {
    height: calc(100vh - 328px);
}
.h-full-minus-296 {
    height: calc(100vh - 296px);
}
.h-half-minus-131 {
    height: calc(42.5vh - 131px);
}

.cvs-grid-loader {
    border-top-color: white !important;
    -webkit-animation: cvs-grid-spinner 1.5s linear infinite;
    animation: cvs-grid-spinner 1.5s linear infinite;
}

@-webkit-keyframes cvs-grid-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes cvs-grid-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
// .ai-textarea textarea:first-child {
//     @apply opacity-0 #{'!important'};
  
//     &:focus, &:active, &:hover, &:visited {
//       @apply opacity-100 #{'!important'};
//     }
//   }

@media screen and (min-width: 992px) {
    .lg\:max-w-1\/4 {
        max-width: 25%;
    }
}