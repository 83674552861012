/* -------------------------------------------------------------------------- */
/*  @ layout
/* -------------------------------------------------------------------------- */
.cvsui-page-container {
	@apply w-full;
}

.cvsui-layout-header-logo {
	@apply h-7;
}

.cvsui-layout-header-title-icon {
	@apply cvsui-text-default-label;
}

.cvsui-layout-header-title {
	@apply text-3xl font-bold cvsui-text-default-label-dark -ml-0.5 sm:truncate sm:tracking-tight #{!important};
}

/* -------------------------------------------------------------------------- */
/*  @ CHIPS
/* -------------------------------------------------------------------------- */
.cvsui-group {
	@apply flex flex-wrap items-center gap-2;

	&.cvsui-group-row {
		@apply flex-row flex-nowrap;
	}
}

.cvsui-chip {
	@apply inline-flex items-center justify-center px-1.5 py-1 text-sm font-medium leading-4 rounded-md border border-transparent cursor-default -tracking-tight;

	&.cvsui-chip-sm {
		@apply text-sm px-1 py-0.5;
	}
	&.cvsui-chip-3xl {
		@apply text-3xl leading-6 px-2 py-2;
	}
	&.cvsui-chip-success {
		@apply text-green-700 bg-green-50 border-green-100;
	}

	&.cvsui-chip-warn {
		@apply text-yellow-700 bg-yellow-50 border-yellow-100;
	}

	&.cvsui-chip-info {
		@apply text-blue-700 bg-blue-50 border-blue-100;
	}

	&.cvsui-chip-danger {
		@apply text-warn-700 bg-warn-50 border-warn-100;
	}

	&.cvsui-chip-primary {
		@apply text-primary bg-primary-50 border-primary-100;
	}

	&.cvsui-chip-secondary {
		@apply text-secondary bg-secondary-50 border-secondary-100;
	}

	&.cvsui-chip-thirdary {
		@apply text-thirdary bg-thirdary-50 border-thirdary-100;
	}

	&.cvsui-chip-default {
		@apply cvsui-text-default-label bg-slate-50 border-slate-100;
	}
}

/* -------------------------------------------------------------------------- */
/*  @ MESSAGES
/* -------------------------------------------------------------------------- */
.cvsui-message {
	@apply cvsui-rounded cvsui-shadow-sm flex flex-row items-center justify-start gap-x-1.5 px-3 py-2 text-lg;

	&.cvsui-message-my {
		@apply my-1.5;
	}

	&.cvsui-message-success {
		@apply border-green-300 bg-green-100 text-green-800;

		.cvsui-message-icon {
			@apply text-green-800;
		}
	}

	&.cvsui-message-info {
		@apply border-blue-300 bg-blue-100 text-blue-800;

		.cvsui-message-icon {
			@apply text-blue-800;
		}
	}

	&.cvsui-message-warning {
		@apply border-yellow-300 bg-yellow-100 text-yellow-800;

		.cvsui-message-icon {
			@apply text-yellow-800;
		}
	}

	&.cvsui-message-error {
		@apply border-red-300 bg-red-100 text-red-800;

		.cvsui-message-icon {
			@apply text-red-800;
		}
	}

	&.cvsui-message-sm {
		@apply gap-x-0.5 px-2 py-1 text-sm;
	}

	&.cvsui-message-md {
		@apply gap-x-1 px-2.5 py-1.5 text-md;
	}

	&.cvsui-message-lg {
		@apply text-lg;
	}

	&.cvsui-message-xl {
		@apply text-xl;
	}

	.cvsui-message-x-icon {
		@apply cursor-pointer transition-all icon-size-4 hover:scale-125 active:scale-95;
	}
}

/* -------------------------------------------------------------------------- */
/*  @ FORMS
/* -------------------------------------------------------------------------- */

.cvsui-form-label {
	@apply text-base font-semibold leading-7 cvsui-text-default-label-dark;
}
.mat-mdc-text-field-wrapper{
	flex:none !important;
}
.cvsui-form-field-date {
	.mdc-icon-button{
		@apply -mr-1.5 #{!important};
	}
}
.cvsui-form {
	@apply w-full #{!important};

	.cvsui-form-actions {
		@apply flex items-center pt-6 #{!important};

		&.cvsui-form-actions-align-left {
			@apply justify-start #{!important};
		}

		&.cvsui-form-actions-align-right {
			@apply justify-end #{!important};
		}

		&.cvsui-form-actions-align-center {
			@apply justify-center #{!important};
		}

		&.cvsui-form-actions-align-between {
			@apply justify-between #{!important};
		}

		&.cvsui-form-actions-gap-sm {
			@apply gap-2 #{!important};
		}

		&.cvsui-form-actions-gap-md {
			@apply gap-4 #{!important};
		}

		&.cvsui-form-actions-gap-lg {
			@apply gap-6 #{!important};
		}

		&.cvsui-form-actions-gap-xl {
			@apply gap-8 #{!important};
		}
	}

	&.cvsui-form-horizontal {
		.mat-mdc-form-field {
			@apply mb-6 flex flex-row items-center #{!important};

			&:has(.mat-mdc-form-field-error) {
				@apply mb-2 #{!important};
			}
		}
	}

	&.cvsui-form-vertical {
		.mat-mdc-form-field {
			@apply mb-6 flex flex-col items-center #{!important};

			&:has(.mat-mdc-form-field-error) {
				@apply mb-2 #{!important};
			}
		}
	}

	&.cvsui-form-columns {
		.cvsui-section-group {
			@apply space-y-6;
		}

		.cvsui-section {
			@apply grid grid-cols-1 gap-x-8 gap-y-10 border-b cvsui-default-divider-color pb-6 md:grid-cols-3;
		}

		.cvsui-section-header {
			.cvsui-section-title {
				@apply text-base font-semibold leading-7 cvsui-text-default-label-dark;
			}

			.cvsui-section-help {
				@apply mt-1 text-sm leading-6 cvsui-text-default-label-dark;
			}
		}

		.cvsui-section-container {
			@apply grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2;
		}

		.cvsui-full-form-field {
			@apply sm:col-span-4 #{!important};
		}

		.cvsui-full-form-field-full {
			@apply sm:col-span-6 #{!important};
		}
	}

	.cvsui-form-field-hidden {
		&.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
			@apply hidden;
		}
	}
}
.cvsui-mat-form-field-group {
	@apply flex flex-col md:flex-row items-center justify-between border rounded-md p-px divide-x divide-[#CBD5E1] #{!important};
	border-color: rgb(203 213 225 / 1) !important;
	.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
		@apply border-0 rounded-none shadow-none #{!important};
	}
	.mat-mdc-form-field:first-child {
		@apply rounded-l #{!important};
		.mat-mdc-text-field-wrapper {
			@apply rounded-l #{!important};
		}
	}
	.mat-mdc-form-field:last-child  {
		@apply rounded-r  #{!important};
		.mat-mdc-text-field-wrapper {
			@apply rounded-r #{!important};
		}
	}
}
/* -------------------------------------------------------------------------- */
/*  @ DRAG AND DROP
/* -------------------------------------------------------------------------- */
.cvsui-drag-list-header {
	@apply flex items-center justify-start bg-slate-50 rounded-lg px-1.5;
}

.cdk-drag-preview {
	@apply bg-white/80 box-border rounded-md shadow-md;
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cvsui-drag-list.cdk-drop-list-dragging .cvsui-drag-list-item:not(.cdk-drag-placeholder) {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cvsui-drag-list-placeholder {
	@apply min-h-2 bg-thirdary border-thirdary-600 border-dashed;
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* -------------------------------------------------------------------------- */
/*  @ BUTTONS
/* -------------------------------------------------------------------------- */
.cvsui-animate-rotate-90-once {
	@apply transition-all duration-300 group-hover:rotate-270 ease-in-out;
}

.cvsui-button-icon {
	@apply flex items-center justify-center cursor-pointer h-6 min-h-6 w-6 min-w-6 ease-in-out transition-all active:scale-95 #{!important};

	&.cvsui-button-icon-bordered {
		@apply border #{!important};
	}

	&.cvsui-button-icon-borderless {
		@apply border-0 #{!important};
	}

	&.cvsui-button-icon-primary {
		@apply bg-primary text-on-primary border-transparent hover:bg-primary-700 #{!important};

		.mat-icon {
			@apply text-on-primary #{!important};
		}
	}

	&.cvsui-button-icon-secondary {
		@apply bg-secondary text-on-secondary border-transparent hover:bg-secondary-700 #{!important};

		.mat-icon {
			@apply text-white #{!important};
		}
	}

	&.cvsui-button-icon-accent {
		@apply bg-accent-500 text-accent-800 border-transparent hover:bg-accent-700 #{!important};

		.mat-icon {
			@apply text-white #{!important};
		}
	}

	&.cvsui-button-icon-warn {
		@apply bg-warn-500 text-on-warn border-transparent hover:bg-warn-700 #{!important};

		.mat-icon {
			@apply text-on-warn #{!important};
		}
	}

	&.cvsui-button-icon-thirdary {
		@apply bg-thirdary-500 text-white border-transparent hover:bg-thirdary-700 #{!important};

		.mat-icon {
			@apply text-white #{!important};
		}
	}

	&.cvsui-button-icon-active {
		.mat-mdc-button-persistent-ripple {
			@apply bg-black/10;
		}
	}

	&.cvsui-button-icon-xs {
		@apply h-6 min-h-6 w-6 min-w-6 #{!important};

		.mat-mdc-button-touch-target {
			@apply h-6 min-h-6 w-6 min-w-6 #{!important};
		}
	}

	&.cvsui-button-icon-sm {
		@apply h-8 min-h-8 w-8 min-w-8 #{!important};

		.mat-mdc-button-touch-target {
			@apply h-8 min-h-8 w-8 min-w-8 #{!important};
		}
	}

	&.cvsui-button-icon-md {
		@apply h-8 min-h-8 w-8 min-w-8 #{!important};

		.mat-mdc-button-touch-target {
			@apply h-8 min-h-8 w-8 min-w-8 #{!important};
		}
	}

	&.cvsui-button-icon-lg {
		@apply h-10 min-h-10 w-10 min-w-10 #{!important};

		.mat-mdc-button-touch-target {
			@apply h-10 min-h-10 w-10 min-w-10 #{!important};
		}
	}

	&.cvsui-button-icon-shadow {
		--tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
		--tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
		box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
	}

	&.cvsui-button-icon-rounded-lg {
		@apply rounded-lg #{!important};

		.mat-mdc-button-persistent-ripple {
			@apply rounded-lg #{!important};
		}
	}

	&.cvsui-button-icon-rounded-md {
		@apply rounded-md #{!important};

		.mat-mdc-button-persistent-ripple {
			@apply rounded-md #{!important};
		}
	}

	&.cvsui-button-icon-rounded-sm {
		@apply rounded-sm #{!important};

		.mat-mdc-button-persistent-ripple {
			@apply rounded-sm #{!important};
		}
	}
}

.cvsui-button {
	@apply w-full px-2.5 #{!important};

	&.cvsui-button-disabled {
		@apply cursor-not-allowed;
	}
	.cvsui-button__icon {
		@apply  text-white #{!important};
	}
	&.cvsui-button-sm {
		@apply h-6 min-h-6;

		.cvsui-button__label {
			@apply text-sm;
		}

		.cvsui-button__icon {
			@apply icon-size-4 text-white #{!important};
		}
	}

	&.cvsui-button-md {
		@apply h-8 min-h-8;

		.cvsui-button__label {
			@apply text-md;
		}

		.cvsui-button__icon1 {
			@apply icon-size-4;
		}
	}

	&.cvsui-button-lg {
		@apply h-10 min-h-10;

		.cvsui-button__label {
			@apply text-lg;
		}

		.cvsui-button__icon {
			@apply text-white #{!important};
		}
	}

	&.cvsui-button-xl {
		@apply h-12 min-h-12;

		.cvsui-button__label {
			@apply text-xl;
		}

		.cvsui-button__icon {
			@apply text-white #{!important};
		}
	}
}

/* -------------------------------------------------------------------------- */
/*  @ ANCHOR
/* -------------------------------------------------------------------------- */
a.cvsui-anchor {
	@apply inline-block cursor-pointer underline transition-transform duration-200 ease-in-out;

	&:hover {
		@apply text-primary no-underline;
	}
}

/* -------------------------------------------------------------------------- */
/*  @ MAT CHEKBOX
/* -------------------------------------------------------------------------- */
.cvsui-fieldset {
	@apply cvsui-rounded text-sm cvsui-default-border px-2 py-0.5;

	legend {
		@apply text-base font-medium cvsui-text-default-label-dark m-0 px-1.5;
	}
}

/* -------------------------------------------------------------------------- */
/*  @ MAT CHEKBOX
/* -------------------------------------------------------------------------- */
.cvsui-matcheckbox {
	&:has(label:empty) {
		.mdc-form-field {
			@apply p-0;
		}
	}
	&.cvsui-matcheckbox-trim{
		.mdc-form-field {
			@apply p-0 #{!important};
		}
	}
	&.cvsui-matcheckbox-md {
		--mdc-checkbox-state-layer-size: 28px;

		.mat-mdc-checkbox-touch-target {
			width: 32px;
			height: 32px;
		}

		.mdc-checkbox__background,
		.mdc-checkbox {
			width: 16px;
			height: 16px;
		}

		.mdc-checkbox {
			flex: 0 0 16px;
		}

		label {
			@apply text-md leading-4;
		}
	}

	&.cvsui-matcheckbox-lg {
		--mdc-checkbox-state-layer-size: 32px;

		.mat-mdc-checkbox-touch-target {
			width: 36px;
			height: 36px;
		}

		.mdc-checkbox__background,
		.mdc-checkbox {
			width: 18px;
			height: 18px;
		}

		.mdc-checkbox {
			flex: 0 0 18px;
		}

		label {
			@apply text-md leading-4;
		}
	}
}

.loading-panel {
	@apply pointer-events-none absolute inset-0 z-70 flex items-center justify-center rounded-lg bg-white/75;
}

/* -------------------------------------------------------------------------- */
/*  @ CARD
/* -------------------------------------------------------------------------- */
.cvsui-card {
	@apply cvsui-rounded cvsui-shadow-md cvsui-container-p bg-white;
	&.cvsui-card-borderless{
		@apply border-transparent #{!important};
	}
}

/* -------------------------------------------------------------------------- */
/*  @ TABLE
/* -------------------------------------------------------------------------- */
cvs-table-toolbar1 {
	@apply mb-4 block w-full;
}

.cvs-table1 {
	@apply relative flex w-full flex-col;

	--cvsui-table-cell-padding-x: 0;
	--cvsui-table-cell-padding-y: 0;
	--cvsui-table-cell-font-size: 0.875rem;
	--cvsui-table-header-cell-font-size: 0.875rem;
	&.cvs-table-lg {
		--cvsui-table-cell-padding-x: 6px;
		--cvsui-table-cell-padding-y: 6px;
		--cvsui-table-cell-font-size: 14px;
		--cvsui-table-header-cell-font-size: 13px;
	}

	&.cvs-table-md {
		--cvsui-table-cell-padding-x: 4px;
		--cvsui-table-cell-padding-y: 4px;
		--cvsui-table-cell-font-size: 12px;
		--cvsui-table-header-cell-font-size: 11px;
	}

	&.cvs-table-sm {
		--cvsui-table-cell-padding-x: 2px;
		--cvsui-table-cell-padding-y: 2px;
		--cvsui-table-cell-font-size: 10px;
		--cvsui-table-header-cell-font-size: 9px;
	}

	&.cvs-table-xs {
		--cvsui-table-cell-padding-x: 0;
		--cvsui-table-cell-padding-y: 0;
		--cvsui-table-cell-font-size: 8px;
		--cvsui-table-header-cell-font-size: 7px;
	}

	&.cvs-table-layout-auto, &.cvs-table-layout-fixed {
		@media screen and (min-width: 599px) {
			--cvsui-table-cell-padding-x: 4px;
			--cvsui-table-cell-padding-y: 2px;
			--cvsui-table-cell-font-size: 12px;
			--cvsui-table-header-cell-font-size: 11px;
		}

		@media screen and (min-width: 600px) {
			--cvsui-table-cell-padding-x: 6px;
			--cvsui-table-cell-padding-y: 2px;
			--cvsui-table-cell-font-size: 12px;
			--cvsui-table-header-cell-font-size: 11px;
		}

		@media screen and (min-width: 960px) {
			--cvsui-table-cell-padding-x: 8px;
			--cvsui-table-cell-padding-y: 2px;
			--cvsui-table-cell-font-size: 13px;
			--cvsui-table-header-cell-font-size: 12px;
		}

		@media screen and (min-width: 1280px) {
			--cvsui-table-cell-padding-x: 12px;
			--cvsui-table-cell-padding-y: 4px;
			--cvsui-table-cell-font-size: 13px;
			--cvsui-table-header-cell-font-size: 12px;
		}

		@media screen and (min-width: 1440px) {
			--cvsui-table-cell-padding-x: 14px;
			--cvsui-table-cell-padding-y: 4px;
			--cvsui-table-cell-font-size: 14px;
			--cvsui-table-header-cell-font-size: 13px;
		}
	}

	.cvs-table-panel {
		width: 100%;
		overflow: auto;
	}

	.cvsui-table-container {
		@apply rounded-lg bg-white px-2 py-1.5 cvsui-text-default-label-dark;
	}

	&.cvsui-table-shadow > .cvsui-table-container {
		@apply cvsui-shadow-md;
	}

	cvs-table-cell {
		display: contents !important;
	}

	.cvsui-table-cell,
	.cvsui-table-header-cell {
		@apply bg-white opacity-100;
		padding: var(--cvsui-table-cell-padding-y, 12px) var(--cvsui-table-cell-padding-x, 0) !important;
		font-size: var(--cvsui-table-cell-font-size, 14px) !important;
	}
	.cvsui-table-header-cell{
		@apply uppercase;
		font-size: var(--cvsui-table-header-cell-font-size, 14px) !important;
	}
	.cvsui-table-cell-nowrap,
	.cvsui-table-header-cell-nowrap {
		@apply whitespace-nowrap;
	}

	.cvsui-table-cell-ellipsis,
	.cvsui-table-header-cell-ellipsis {
		@apply whitespace-nowrap overflow-hidden overflow-ellipsis;
	}

	.cvsui-table-cell-left,
	.cvsui-table-header-cell-left {
		text-align: left !important;

		.mat-sort-header-container {
			@apply justify-start;
		}
	}

	.cvsui-table-cell-center,
	.cvsui-table-header-cell-center {
		text-align: center !important;

		.mat-sort-header-container {
			@apply justify-center;
		}
	}

	.cvsui-table-cell-right,
	.cvsui-table-header-cell-right {
		text-align: right !important;

		.mat-sort-header-container {
			@apply flex-row-reverse justify-start;

			.mat-sort-header-arrow {
				margin: 0 6px 0 0 !important;
			}
		}
	}

	.cvsui-table-header-row,
	.cvsui-table-row {
		height: auto !important;
	}

	.cvsui-table-layout-auto {
		table-layout: auto !important;
	}

	.cvsui-table-layout-fixed {
		table-layout: fixed !important;
	}

	.cvsui-table-row-selected {
		.cvsui-table-cell {
			@apply bg-green-50/20 text-primary;
		}
	}

	&.cvs-table-striped {
		.cvsui-table-row-even .cvsui-table-cell {
			background-color: var(--cvsui-default-bg-color) !important;
		}
	}
	.cvsui-table-row-expanded-parent.cvsui-table-row-odd .cvsui-table-cell,
	.cvsui-table-row-expanded-parent.cvsui-table-row-even .cvsui-table-cell{
		@apply	 bg-thirdary-50 #{!important}  ;
	}
	&.cvs-table-bordered {
		> .cvsui-table-container {
			border-width: 1px !important;
			border-style: solid !important;
			border-color: var(--cvsui-default-border-color) !important;
		}

		tr:not(.cvsui-table-row-nodata) > .cvsui-table-cell {
			border-bottom-width: 0 !important;
			border-top-width: 1px !important;
			border-top-style: dashed !important;
			border-top-color: var(--cvsui-default-divider-color) !important;
		}

		/*tr.cvsui-table-row:last-of-type .cvsui-table-cell {
            border-top-width: 0 !important;
        }*/
		.cvsui-table-header-cell {
			border-bottom-width: 2px !important;
			border-bottom-style: solid !important;
			border-bottom-color: var(--cvsui-default-divider-color) !important;
		}

		.cvsui-table-pagination {
			border-top-width: 2px !important;
			border-top-style: solid !important;
			border-top-color: var(--cvsui-default-divider-color) !important;
		}
	}

	/*search*/
	.cvsui-table-toolbar {
		@apply flex items-center justify-start bg-slate-100 rounded-lg p-1.5;
	}

	.cvsui-table-search-field {
		@apply w-64 min-w-64;

		.mat-mdc-form-field-icon-prefix>.mat-icon {
			@apply mr-1 #{!important};
		}

		.mat-mdc-form-field-flex {
			@apply pl-1.5 #{!important};
		}

		.mat-mdc-form-field-infix {
			@apply min-h-8 h-8 #{!important};
		}
	}

	/*pagination */
	.cvsui-table-pagination {
		padding-top: var(--cvsui-table-cell-padding) !important;
		font-size: var(--cvsui-table-cell-font-size) !important;

		/* mat paginator reset */
		.mat-mdc-paginator-container {
			@apply m-0 mt-0.5 flex min-h-0 flex-row items-center justify-between p-0;
		}

		.mat-mdc-paginator-page-size-select {
			@apply w-15 #{!important};
		}

		.mat-mdc-paginator-page-size,
		.mat-mdc-paginator-range-actions {
			@apply m-0 min-h-0 p-0;
		}

		.mat-mdc-icon-button {
			@apply m-0 p-0;
			height: auto !important;
		}

		.mat-mdc-form-field-infix {
			@apply h-auto min-h-5 py-0.5 #{!important};
		}

		.mat-mdc-select-arrow svg {
			@apply cvsui-text-default-label;
		}
	}

	.mat-column-checkbox,
	.mat-column-expand {
		@apply mx-auto;

		.cvsui-matcheckbox,
		.cvsui-button-icon {
			vertical-align: middle;
		}
	}
}

.cvsui-table1 {
	.cvsui-table-row-not-expanded {
		@apply hidden border-b-0;
	}
	
	.cvsui-table-row-expanded {
		.mat-column-expandedDetail {
			@apply p-2 pt-0 bg-thirdary-50 #{!important}  ;
			> .cvsui-table-expanded-panel{
				@apply border border-dashed border-thirdary-600 #{!important}  ;
			}
		}
	}
}

/* -------------------------------------------------------------------------- */
/*  @ MAT MENU
/* -------------------------------------------------------------------------- */
.cvsui-table-toolbar-actions {
	@apply cvsui-shadow-lg #{!important};
	.mat-mdc-menu-content{
		@apply rounded-md shadow-none drop-shadow-none p-1.5 #{!important};
	}
	.mat-mdc-menu-item {
		@apply min-h-0 p-1 rounded-md #{!important};
	}
}

/* -------------------------------------------------------------------------- */
/*  @ DAILOGS
/* -------------------------------------------------------------------------- */
// cvs-dialog {
// 	@apply flex flex-col flex-grow;
// }

// .cdk-global-overlay-wrapper {
// 	@apply flex flex-col absolute justify-end items-end #{!important};
// }

// .cvsui-dialog-panel {
// 	@apply overflow-hidden shadow-2xl rounded-md flex flex-col flex-grow m-4 #{!important};

// 	&.cvsui-dialog-panel-md {
// 		@apply w-128 max-w-128 #{!important};
// 	}

// 	.mat-mdc-dialog-container .mdc-dialog__surface {
// 		@apply rounded-md p-2 #{!important};
// 	}
// }

// .cvsui-dialog-title {
// 	@apply text-2xl font-semibold cvsui-text-default-label-dark px-2 pb-1 -mx-2 border-b border-dashed cvsui-default-border-color before:h-0 tracking-tight #{!important};
// }

// .cvsui-dialog-content {
// 	@apply min-h-16 max-h-none p-0 flex flex-row flex-auto overflow-auto #{!important};
// }

// .cvsui-dialog {
// 	@apply w-full ;

// 	.mat-mdc-dialog-title {
// 		@apply text-2xl font-semibold cvsui-text-default-label-dark px-2 pb-2.5 before:h-6 #{!important};
// 	}
// 	.mdc-dialog__content {
// 		@apply px-2 pb-2.5 #{!important};
// 	}
// }

// .cvsui-dialog-message {
// 	@apply break-words;
// }

// .cvsui-dialog-wrapper {
// 	@apply flex grow;
// }

// .cvsui-dialog-input-wrapper {
// 	@apply flex flex-row box-border pt-2 #{!important};

// 	.cvsui-dialog-input {
// 		@apply flex-grow box-border #{!important};
// 	}
// }

// .cvsui-dialog-actions {
// 	@apply flex flex-row box-border gap-x-2 pb-0 border-t border-dashed cvsui-default-border-color -mx-2 #{!important};

// 	.cvsui-dialog-spacer {
// 		@apply flex-grow;
// 	}
// }

// @media screen and (max-width: 480px) {
// 	.cvsui-dialog-wrapper {
// 		flex-direction: column;
// 	}
// }

/* -------------------------------------------------------------------------- */
/*  @ tabs
/* -------------------------------------------------------------------------- */
.cvsui-tabs {
	@apply flex flex-row flex-wrap divide-x divide-thirdary/20 overflow-hidden min-w-280;

	.cvsui-tab-item-link {
		@apply leading-8 uppercase inline-block px-2.5 py-1.5 border-transparent hover:text-primary hover:border-primary hover:no-underline;
	}

	.cvsui-tab-item-link-active  {
			@apply text-primary  border-primary border-b-2;
	}
}

/* -------------------------------------------------------------------------- */
/*  @ datalist
/* -------------------------------------------------------------------------- */
.cvsui-data-list {
	@apply flex flex-col gap-px items-start justify-start;
	&.cvsui-data-list-reverse{
		@apply flex-col-reverse;
	}
	.cvsui-data-list-term {
		@apply text-md leading-none uppercase cvsui-text-default-label;
	}

	.cvsui-data-list-desc {
		@apply text-lg cvsui-text-default-label-dark min-h-5;
	}
}
